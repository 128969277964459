<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="dashboard-main twitter-load">
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="dashboard-grid">
      <div class="twitter-load--filter twelve-col">
        <div class="dashboard-box small-box twitter-load--filter--date">
          <div class="dashboard-box--label">
            <h2><v-icon x-small>mdi-calendar</v-icon> Rango de fechas</h2>
          </div>
          <div class="dashboard-box--content" @click="showDatePickerModal()">
            {{ dateRangeFullText }}
            <v-icon>mdi-chevron-down</v-icon>
          </div>
          <custom-modal ref="datePicker">
            <template v-slot:header-title>
              <h3>Selecciona un rango de fechas</h3>
            </template>
            <template v-slot:body>
              <div class="date-picker-wrapper">
                <v-date-picker v-model="dates" range no-title color="red accent-2"></v-date-picker>
                <v-text-field v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar"
                  readonly color="red accent-2"></v-text-field>
              </div>
            </template>
            <template v-slot:actions>
              <v-btn color="red accent-2" text @click="hideDatePickerModal()">
                Cancelar
              </v-btn>

              <v-btn color="red accent-2" text @click="setDatesFilter()">
                Aceptar
              </v-btn>
            </template>
          </custom-modal>
        </div>
        <div class="dashboard-box twitter-load--filter--radio-buttons">
          <div class="twitter-load--filter--radio-buttons__label">
            <h2>Mostrar:</h2>
          </div>
          <div class="twitter-load--filter--radio-buttons__checkboxes">
            <!-- vuetify checkbox with label -->
            <v-checkbox v-model="positivos" label="Positivos" color="teal accent-3"
              hide-details></v-checkbox>
            <v-checkbox v-model="negativos" label="Negativos" color="deep-purple accent-3"
              hide-details></v-checkbox>
            <v-checkbox v-model="neutros" label="Neutros" color="orange accent-3"
              hide-details></v-checkbox>
            <!-- vuetify button to rest filters, with cross icon prepend -->
            <v-btn class="twitter-load--filter--radio-buttons__checkboxes__reset"
              @click="resetFilters" text small>
              <v-icon>mdi-close</v-icon>
              Reset
            </v-btn>
          </div>
        </div>
      </div>
      <div class="twitter-load--insights twelve-col">
        <div class="dashboard-box twitter-load--insights__total insight-box">
          <div class="dashboard-box--label">
            <h2><v-icon x-small>mdi-twitter</v-icon>Total de Tweets:</h2>
          </div>
          <div class="dashboard-box--content insights--total">
            <span class="insights--total__value">{{ totalTweets }}</span>
            <span class="insights--total__label">tweets</span>
          </div>
        </div>
        <div class="dashboard-box twitter-load--insights__positive insight-box">
          <div class="dashboard-box--label">
            <h2>
              <v-icon x-small color="teal accent-3">mdi-emoticon-happy</v-icon>Tweets positivos
            </h2>
          </div>
          <div class="
              dashboard-box--content
              insights--positive
              insights-box--content
            ">
            <div class="
                insights--positive--wrapper
                insights-box--content--wrapper
                positive
              ">
              <span class="
                  insights--positive__value
                  insights-box--content--wrapper__value
                ">{{ positivos ?  totalPositive : 0 }}</span>
              <span class="
                  insights--positive__label
                  insights-box--content--wrapper__label
                ">tweets</span>
            </div>
            <div class="insights--positive__chip insights-filter-chip">
              <v-chip color="teal accent-3" text-color="teal darken-4" pill small>
                {{ getPercentage(positivos ?  totalPositive : 0) }}%</v-chip>
            </div>
          </div>
        </div>
        <div class="dashboard-box twitter-load--insights__negative insight-box">
          <div class="dashboard-box--label">
            <h2>
              <v-icon x-small color="deep-purple accent-3">mdi-emoticon-sad</v-icon>Tweets negativos
            </h2>
          </div>
          <div class="
              dashboard-box--content
              insights--negative
              insights-box--content
            ">
            <div class="
                insights--negative--wrapper
                insights-box--content--wrapper
                negative
              ">
              <span class="
                  insights--negative__value
                  insights-box--content--wrapper__value
                ">{{ negativos ? totalNegative : 0 }}</span>
              <span class="
                  insights--negative__label
                  insights-box--content--wrapper__label
                ">tweets</span>
            </div>
            <div class="insights--negative__chip insights-filter-chip">
              <v-chip color="deep-purple accent-3" text-color="deep-purple lighten-5" pill
                small>{{ getPercentage(negativos ? totalNegative : 0) }}%</v-chip>
            </div>
          </div>
        </div>
        <div class="dashboard-box twitter-load--insights__neutral insight-box">
          <div class="dashboard-box--label">
            <h2>
              <v-icon x-small color="orange accent-3">mdi-emoticon-neutral</v-icon>Tweets neutros
            </h2>
          </div>
          <div class="
              dashboard-box--content
              insights--neutral
              insights-box--content
            ">
            <div class="
                insights--neutral--wrapper
                insights-box--content--wrapper
                neutral
              ">
              <span class="
                  insights--negative__value
                  insights-box--content--wrapper__value
                ">{{ neutros ? totalNeutral : 0 }}</span>
              <span class="
                  insights--neutral__label
                  insights-box--content--wrapper__label
                ">tweets</span>
            </div>
            <div class="insights--neutral__chip insights-filter-chip">
              <v-chip color="orange accent-3" text-color="orange lighten-5" pill small>
                {{ getPercentage(neutros ? totalNeutral : 0) }}%</v-chip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <standard-table ref="tweetsTable" @updateTotal="updateTweetsTotal">
    </standard-table>
  </div>
</template>

<script>

// eslint-disable-next-line import/no-cycle
import tweetService from '@/services/tweet.service';

import CustomModal from '@/components/Modal/CustomModal.vue';
import StandardTable from '@/components/Tables/TweetsTable.vue';
/* eslint-disable max-len */

export default {

  name: 'TwitterLoad',
  components: {
    CustomModal,
    StandardTable,
  },
  data: () => ({
    datePickerModal: false,
    totalTweets: null,
    items: [
      {
        text: 'Proyectos',
        disabled: false,
        to: '/seleccionar-proyecto',
      },
      {
        text: '',
        disabled: false,
        to: '/carga-de-twitter',
      },
      {
        text: 'Carga de Twitter',
        disabled: true,
        to: '#',
      },
    ],
    /* Checkboxes */
    positivos: true,
    negativos: true,
    neutros: true,

    // Start and end dates
    totalNegative: 0,
    totalNeutral: 0,
    totalPositive: 0,
    totalWithSentiment: 0,

    filter: {
      from: null,
      to: null,
      sentiments: '1,2,3,positive,negative,neutral',
    },

    loading: true,
    dates: null,
  }),

  mounted() {
    this.dates = [tweetService.getDayXDaysAgo(7), tweetService.getToday()];
    this.setDatesFilter();
    if (this.$store.state.project != null) {
      this.items[1].text = this.$store.state.project;
    } else {
      this.$router.push('/seleccionar-proyecto');
    }
  },
  methods: {
    updateTweetsTotal(val) {
      this.totalTweets = val;
    },
    showDatePickerModal() {
      this.$refs.datePicker.openModal();
    },
    hideDatePickerModal() {
      this.$refs.datePicker.closeModal();
    },
    updateSentiment(val) {
      if (this.filterSentimentsArray.includes(val)) {
        this.filterSentimentsArray.splice(this.filterSentimentsArray.indexOf(val), 1);
      } else {
        this.filterSentimentsArray.push(val);
      }
      // transform array to string
      this.filter.sentiments = this.filterSentimentsArray.join(',');
      this.updateFilter();
    },
    setDatesFilter() {
      if (this.dates[0] == null && this.dates[1] == null) {
        this.hideDatePickerModal();
        return;
      } if (this.dates[0] == null || this.dates[1] == null) {
        return;
      }
      if (this.dates[0] > this.dates[1]) {
        // eslint-disable-next-line prefer-destructuring
        this.filter.from = this.dates[1];
        // eslint-disable-next-line prefer-destructuring
        this.filter.to = tweetService.add1Day(this.dates[0]);
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.filter.from = this.dates[0];
        // eslint-disable-next-line prefer-destructuring
        this.filter.to = tweetService.add1Day(this.dates[1]);
      }
      this.filter.from = this.parseStringToDateWith00MonthAndDay(this.filter.from);
      this.filter.to = this.parseStringToDateWith00MonthAndDay(this.filter.to);
      this.hideDatePickerModal();
      this.updateFilter();
    },
    parseStringToDateWith00MonthAndDay(date) {
      let newDate = new Date(date);
      const orgDate = new Date(date);
      if (orgDate.getMonth() < 10 && orgDate.getDate() < 10) {
        newDate = `${orgDate.getFullYear()}-0${orgDate.getMonth() + 1}-0${orgDate.getDate()}`;
      } else if (orgDate.getMonth() < 10) {
        newDate = `${orgDate.getFullYear()}-0${orgDate.getMonth() + 1}-${orgDate.getDate()}`;
      } else if (orgDate.getDate() < 10) {
        newDate = `${orgDate.getFullYear()}-${orgDate.getMonth() + 1}-0${orgDate.getDate()}`;
      } else {
        newDate = `${orgDate.getFullYear()}-${orgDate.getMonth() + 1}-${orgDate.getDate()}`;
      }
      return newDate;
    },

    updateFilter() {
      this.getStats();
      this.$refs.tweetsTable.page = 1;
      this.$refs.tweetsTable.updateFilter(this.filter.from, this.filter.to, this.filter.sentiments);
    },

    resetFilters() {
      this.dates = [tweetService.getDayXDaysAgo(7), tweetService.getToday()];
      this.positivos = true;
      this.negativos = true;
      this.neutros = true;
      this.setDatesFilter();
    },
    getStats() {
      this.loading = true;
      tweetService.getStats(this.filter.from, this.filter.to)
        .then((response) => {
          this.setStatsSentiment(response);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setStatsSentiment(response) {
      this.totalPositive = response.totalPositive;
      this.totalNegative = response.totalNegative;
      this.totalNeutral = response.totalNeutral;
      this.totalWithSentiment = this.totalPositive + this.totalNegative + this.totalNeutral;
    },

    getPercentage(value) {
      if (this.totalWithSentiment === 0) {
        return 0;
      }
      return Math.round((value * 100) / this.totalWithSentiment);
    },

  },
  computed: {
    filterSentimentsArray() {
      const sentiments = [];
      if (this.positivos) {
        sentiments.push('3');
        sentiments.push('positive');
      }
      if (this.negativos) {
        sentiments.push('1');
        sentiments.push('negative');
      }
      if (this.neutros) {
        sentiments.push('2');
        sentiments.push('neutral');
      }
      return sentiments;
    },
    dateRangeText() {
      if (this.dates) {
        return this.dates.join(' ~ ');
      }
      return '';
    },
    dateRangeFullText() {
      if (this.dates) {
        // format to Miércoles 12 de Diciembre de 2021
        const date1 = new Date(this.dates[0]);
        const date2 = new Date(this.dates[1]);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date1Text = date1.toLocaleDateString('es-ES', options);
        const date2Text = date2.toLocaleDateString('es-ES', options);
        return `${date1Text} ~ ${date2Text}`;
      }
      return '';
    },
  },
  watch: {
    filterSentimentsArray() {
      this.updateSentiment();
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: min-content;
  column-gap: 0.875rem;
  row-gap: 0.875rem;
  margin-bottom: 1em;
  z-index: 0;

  .two-col {
    grid-column: span 2;
  }

  .twelve-col {
    grid-column: 1 / span 14;
  }

  // .one-row {
  //   grid-row: span 1;
  // }
  .three-row {
    grid-row: 1 / span 3;
  }
}

.twitter-load--filter {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.875rem;
  height: 4.5rem;

  .twitter-load--filter--date {
    padding: 0.5rem;
    padding-right: 0.875rem;
    width: 28.57142857%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .twitter-load--filter--radio-buttons {
    padding: 1.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    width: 71.42857143%;

    &__label {
      display: flex;
      align-items: center;

      h2 {
        font-size: 1.125rem;
        margin-bottom: 0;
      }
    }

    &__checkboxes {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 1rem;
      width: 100%;

      &__reset {
        margin-left: auto;
      }
    }
  }
}

.twitter-load--insights {
  display: flex;
  flex-wrap: nowrap;
  height: 7rem;
  gap: 0.875rem;

  .insight-box {
    padding: 0.5rem;
    padding-right: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .insights--total {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 0.2rem;

    &__value {
      font-size: 4rem;
      line-height: 1;
      font-weight: 500;
      margin: 0;
    }

    &__label {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .insights-box--content {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .insights-box--content--wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      gap: 0.2rem;
      margin-bottom: 0.2rem;

      &__value {
        font-size: 2rem;
        line-height: 1;
        font-weight: 500;
        margin: 0;
      }

      &__label {
        font-size: 0.75rem;
        margin: 0;
      }

      &.positive {
        color: #1de9b6;
      }

      &.negative {
        color: #651fff;
      }

      &.neutral {
        color: #ff9100;
      }
    }
  }

  .twitter-load--insights__total {
    width: 35.71428571%;
  }

  .twitter-load--insights__positive,
  .twitter-load--insights__negative,
  .twitter-load--insights__neutral {
    width: 21.42857143%;
  }
}

.dashboard-box--label {
  h2 {
    font-size: 0.75rem;
    margin-bottom: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.15rem;
  }
}
</style>
<style lang="scss">
.breadcrumbs-container {
  .v-breadcrumbs {
    padding-top: 0;
    padding-bottom: 1em;

    a.v-breadcrumbs__item--disabled {
      color: rgba(0, 0, 0, 0.54) !important;
    }

    a {
      color: #f4796b !important;
    }

    a:hover {
      color: ligthen(#f4796b, 40%);
    }
  }
}

.v-text-field__details {
  display: none;
}

.dashboard-box {
  border: solid 1px #e5e5e5;
  border-radius: 4px;
  background-color: #fff;
}

.table-tabs {
  margin-bottom: 1em;
}

.md-switch .md-switch-label {
  min-width: 2.5em !important;
}

.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  margin-bottom: 0;
  min-width: 1.5em;
}

.v-input--selection-controls.v-input {
  margin-top: 0;
}

.twitter-load {
  & .v-picker.v-card.v-picker--date.theme--light {
    position: static;
  }

  & .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox {
    padding-top: 0;
  }
  & .v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox{
    padding-top: 0;
  }
}

// * {
//   border: solid 1px blue;
// }
</style>
<style scoped>
.v-text-field--outlined>>>fieldset {
  background-color: #fee6e6;
  border-color: #ef5350;
  color: #ef5350;
  opacity: 70%;
}

.v-text-field--outlined>>>input {
  color: #ef5350;
}

.tooltip-fuente {
  max-width: 90%;
  word-break: break-word;
  text-align: left;
  line-height: 1.2;
}
</style>
