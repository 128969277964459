import { render, staticRenderFns } from "./NoticiasFavoriteSmallTable.vue?vue&type=template&id=4c05d282&scoped=true&"
import script from "./NoticiasFavoriteSmallTable.vue?vue&type=script&lang=js&"
export * from "./NoticiasFavoriteSmallTable.vue?vue&type=script&lang=js&"
import style0 from "./NoticiasFavoriteSmallTable.vue?vue&type=style&index=0&id=4c05d282&lang=scss&scoped=true&"
import style1 from "./NoticiasFavoriteSmallTable.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c05d282",
  null
  
)

export default component.exports