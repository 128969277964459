<template>
  <div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear
        color="red lighten-1"
        class="linear-progress"
        indeterminate
      ></v-progress-linear>
    </div>
    <md-table
      v-model="noticias"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      :md-sort-fn="customSort"
      class="dashboard_box table_box"
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="" class="cell-favorito">
          <div style="display: inline-flex">
            <md-button
              class="md-icon-button md-dense"
              @click="setFavoriteAction(item)"
            >
              <v-icon :color="setFavoriteColor(item)">{{
                setFavoriteIcon(item)
              }}</v-icon>
            </md-button>
          </div>
        </md-table-cell>
        <md-table-cell md-label="Fuente" md-sort-by="Fuente"
          ><a :href="item.Enlace" target="_blank" rel="noopener">
            <v-icon class="cell-icon">mdi-link</v-icon>{{ item.Fuente }}</a
          >
        </md-table-cell>
        <md-table-cell md-label="Titular">
          <div>
            {{ getItemTitle(item) }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Fecha" md-sort-by="Date"
          >{{ item.Date }}
        </md-table-cell>
        <md-table-cell
          md-label="Predicción"
          md-sort-by="Predicción"
          class="cell-prediccion"
        >
          <v-chip
            color="teal accent-3"
            small
            chip
            dark
            v-if="item.Predicción == 'Si'"
            draggable
          >
            {{ item.Predicción }}
          </v-chip>
          <v-chip
            color="deep-purple lighten-2"
            small
            chip
            dark
            v-if="item.Predicción == 'No'"
            draggable
          >
            {{ item.Predicción }}
          </v-chip>
        </md-table-cell>
        <md-table-cell
          md-label="Probabilidad"
          md-sort-by="Probabilidad"
          class="cell-probabilidad"
        >
          {{ item.Probabilidad.toFixed(2) }}
        </md-table-cell>
        <md-table-cell md-label="Evaluación" class="cell-etiquetado">
          <v-chip
            color="teal accent-3"
            small
            chip
            v-if="
              item.labeled.length > 0 &&
              item.labeled[item.labeled.length - 1].Positivo === 'Si'
            "
            draggable
            @click="openLabeled(item)"
          >
            Si
          </v-chip>
          <v-chip
            color="deep-purple lighten-2"
            small
            chip
            v-if="
              item.labeled.length > 0 &&
              item.labeled[item.labeled.length - 1].Positivo === 'No'
            "
            draggable
            @click="openLabeled(item)"
          >
            No
          </v-chip>
          <v-chip
            v-if="item.labeled.length == 0 && activeHoverItem !== item"
            class="button-add_comment button-in-cell"
            outlined
            small
            @focus="activeHoverItem = item"
            v-on:mouseover="activeHoverItem = item"
            @click="openLabeled(item)"
          >
            Sin evaluar
          </v-chip>
          <v-chip
            @click="openLabeled(item)"
            v-if="activeHoverItem == item"
            class="button-add_comment button-in-cell"
            small
            outlined
            color="red lighten-1"
            @focusout="activeHoverItem = null"
            v-on:mouseout="activeHoverItem = null"
          >
            Añadir evaluación
          </v-chip>
        </md-table-cell>
        <md-table-cell
          md-label="Ubicación precisa"
          md-sort-by="Ubicacion_Precisa"
        >
          <a :href="goToMaps(item.Latitud, item.Longitud)" target="_blank" rel="noopener">
            <v-icon class="cell-icon">mdi-map-marker</v-icon
            >{{ item.Ubicación_Precisa }}</a
          >
        </md-table-cell>
        <md-table-cell md-label="URL" md-sort-by="url_status_code">
          <v-chip
            color="green accent-3"
           small
            chip
            dark
            v-if="item.url_status_code == 200"
            draggable
          >
            OK
          </v-chip>
          <v-chip
            color="red lighten-2"
            small
            dark
            chip
            v-if="item.url_status_code != 200 && item.url_status_code"
            draggable
          >
            KO
          </v-chip>
          <v-chip
            color="blue lighten-2"
            small
            dark
            chip
            v-if="!item.url_status_code"
            draggable
          >
            ??
          </v-chip>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <div class="pagination-container">
      <div class="pagination-options" v-if="!loading">
        Elementos por página:
        <select aria-labelledby="select-page"
        v-model="pageSize" @blur="handlePageSizeChange($event)">
          <option v-for="size in pageSizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
        <div style="float: right">Total de Noticias: {{ this.count }}</div>
      </div>
      <div class="pagination-wrapper">
        <b-pagination
          v-if="!loading"
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          prev-text="Anterior"
          next-text="Siguiente"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
    <v-dialog v-model="showDialog" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Añadir evaluación</span>
        </v-card-title>
        <v-card-text>
          <div class="chips-container">
            <v-chip
              v-if="labeled.result != 'Si'"
              color="teal accent-3"
              class="dialog-chip"
              chip
              draggable
              outlined
              @click="labeled.result = 'Si'"
              >SI
            </v-chip>
            <v-chip
              v-if="labeled.result === 'Si'"
              color="teal accent-3"
              class="dialog-chip"
              chip
              draggable
              dark
              @click="labeled.result = null"
              >SI
            </v-chip>
            <v-chip
              v-if="labeled.result != 'No'"
              color="deep-purple lighten-2"
              class="dialog-chip"
              chip
              draggable
              outlined
              @click="labeled.result = 'No'"
              >NO</v-chip
            >
            <v-chip
              v-if="labeled.result === 'No'"
              color="deep-purple lighten-2"
              class="dialog-chip"
              chip
              draggable
              dark
              @click="labeled.result = null"
              >NO</v-chip
            >
          </div>
          <v-textarea
            outlined
            color="red lighten-1"
            name="input-7-4"
            label="Añadir comentario"
            v-model="labeled.comment"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="dialog-chip"
            color="rgba(0,0,0,.6)"
            text
            @click="showDialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            small
            class="dialog-chip"
            color="red lighten-1"
            text
            @click="saveLabeled()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

export default {
  name: 'small-table',
  props: {},
  data() {
    return {
      dialog: false,
      authService,
      isAdmin: false,
      labeled: {
        result: null,
        comment: null,
        id: null,
      },
      filter: {
        prediction: null,
        ubicacion: null,
        startDate: null,
        minProb: null,
        maxProb: null,
      },
      showDialog: false,
      noticias: [],
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 5,
      pageSizes: [5, 10, 20],

      currentSort: 'Date',
      currentSortOrder: 'asc',
      loading: false,
      cancelTokenSource: null,

      activeHoverItem: null,
    };
  },
  created() {
    if (
      this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol
      === 'ROLE_SUPER_ADMIN'
    ) {
      this.isAdmin = true;
    }
  },

  watch: {
    currentSort() {
      this.getItems();
    },
    currentSortOrder() {
      this.getItems();
    },
  },
  computed: {
    FechaAlta: {
      set(val) {
        this.FechaAlta = val;
      },
      get() {
        if (this.user && this.user.creationTime) {
          return new Date(this.user.creationTime).toISOString().split('T')[0];
        }
        return null;
      },
    },
  },
  components: {
    // DataSelection,
  },
  methods: {
    getItemTitle(item) {
      if (item.Titular_ENG) {
        return item.Titular_ENG;
      } if (item.Titular_ESP) {
        return item.Titular_ESP;
      } if (item.Titular) {
        return item.Titular;
      }
      return 'No disponible';
    },
    setFavoriteColor(item) {
      this.color = null;
      if (item.favorite.length === 0) {
        this.color = 'darkgrey lighten-2';
        return this.color;
      }
      this.color = 'blue lighten-2';
      item.favorite.forEach((ele) => {
        if (
          ele.userID
          === this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub
        ) {
          this.color = 'red lighten-2';
        }
      });
      return this.color;
    },
    setFavoriteIcon(item) {
      if (item.favorite.length === 0) {
        return 'mdi-cards-heart-outline';
      }
      return 'mdi-cards-heart';
    },
    setFavoriteAction(item) {
      if (item.favorite.length === 0) {
        this.saveFavorite(item);
        return;
      }
      this.actionFav = true;
      item.favorite.forEach((ele) => {
        if (
          ele.userID
          === this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub
        ) {
          this.actionFav = false;
          this.id = ele.id;
        }
      });
      if (this.actionFav) {
        this.saveFavorite(item);
      } else {
        this.unSaveFavorite(this.id);
      }
    },
    openLabeled(item) {
      this.showDialog = true;
      this.labeled.id = item.GKGRECORDID;
      if (item.labeled.length > 0) {
        this.labeled.result = item.labeled[item.labeled.length - 1].Positivo;
        this.labeled.comment = item.labeled[item.labeled.length - 1].Comentario;
      } else {
        this.labeled.result = null;
        this.labeled.comment = null;
      }
    },
    saveLabeled() {
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}analizados/labeled`,
          {
            Positivo: this.labeled.result,
            Comentario: this.labeled.comment,
            GKGRECORDID: this.labeled.id,
          },
          {
            headers: authHeader(),
          },
        )
        .then((response) => {
          console.log(response);
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.showDialog = false;
        });
    },
    saveFavorite(item) {
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}analizados/favorite`,
          {
            Fecha: new Date(),
            ProyectoID: '',
            GKGRECORDID: item.GKGRECORDID,
            UserID: this.authService.getDecodedJwtToken(
              this.$store.state.auth.user,
            ).sub,
          },
          {
            headers: authHeader(),
          },
        )
        .then((response) => {
          console.log(response);
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    unSaveFavorite(id) {
      this.loading = true;
      axios
        .delete(`${process.env.VUE_APP_API_URL}analizados/unfavorite/${id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          console.log(response);
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    goToMaps(Latitud, Longitud) {
      if (Latitud && Longitud) {
        return `https://www.google.com/maps/@${Latitud}${','}${Longitud}${',14z'}`;
      }
      return 'https://www.google.com/maps/';
    },
    getRequestParams(page, pageSize) {
      const params = {};

      params.prediccion = this.filter.prediction;
      params.ubicacionPrecisa = this.filter.ubicacion;
      params.minProb = this.filter.minProb;
      params.maxProb = this.filter.maxProb;
      params.projectId = this.$store.state.projectId;

      if (this.filter.startDate) {
        params.fechaInicio = new Date(this.filter.startDate)
          .toISOString()
          .substring(0, 10)
          .replaceAll('-', '/');
      }

      params.sort = this.currentSort;
      params.direction = this.currentSortOrder;

      if (page) {
        params.page = page - 1;
      }

      if (pageSize) {
        params.size = pageSize;
      }

      return params;
    },

    goToUserView(id) {
      return this.$router.push(`${id}`);
    },

    updateFilter(val) {
      this.filter = val;
      this.getItems();
    },

    getItems() {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/all/`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.noticias = response.data.content;
          this.count = response.data.totalElements;
          this.updateTotal();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlePageChange(value) {
      this.page = value;
      this.getItems();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.getItems();
    },
    updateTotal() {
      this.$emit('updateTotal', this.count);
    },
    customSort() {
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.checkboxes-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2em;
}
.md-dialog .md-dialog-container {
  width: 60%;
}
.md-table-cell-container {
  text-align: left;
  .cell-icon {
    margin-right: 0.2em;
  }
}

.cell-probabilidad {
  text-align: center;
}
.loader-overlay {
  position: relative;
}

// .cell-etiquetado {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// * {
//   border: solid 1px blue;
// }
</style>
<style lang="scss">
// *{
//   border:solid 1px blue;
// }
.md-table-head-container.md-ripple.md-disabled {
  .md-table-head-label {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
}
.md-table-cell-container {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5em 1em;
}
.md-table-head-label {
  padding: 0;
  padding-left: 2em;
  padding-right: 0.5em;
}
.table_box {
  min-height: 150px;
  position: relative;
}
.cell-prediccion {
  .md-table-cell-container {
    justify-content: center;
  }
}
.cell-probabilidad {
  .md-table-cell-container {
    justify-content: center;
  }
}
.cell-etiquetado {
  .md-table-cell-container {
    justify-content: center;
  }
  min-width: 13em;
}
.cell-favorito {
  .md-table-cell-container {
    justify-content: center;
  }
}
.cell-comentarios {
  .md-table-cell-container {
    justify-content: center;
  }
}
.md-table-cell {
  height: auto;
}
.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .page-item.active .page-link {
    background-color: #f44e3f !important;
    border-color: #f44e3f !important;
  }
}
.pagination-options {
  margin-top: 1em;
  margin-bottom: 1em;
  min-width: 500px;
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    width: max-content;
  }
}
.button-in-cell {
  margin-left: 0.25;
  margin-right: 0.25em;
  cursor: pointer;
}
.linear-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.chips-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-top: 1em;
  margin-bottom: 1.5em;
  .dialog-chip {
    margin-right: 0.5em;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
