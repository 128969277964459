<template>
  <div>
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="twitter-single">
      <div class="twitter-single--tweet-panel mdtec-card twitter-single__left">
        <div class="mdtec-card--header tweet-panel--header">
          <h2 class="mdtec-card--header__title">Información de la suscripción</h2>
        </div>
        <div class="mdtec-card--body tweet-panel--body">
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__tweet-content">
              <label class="twiter-panel--field__label">Nombre:</label>
              <div class="twiter-panel--field__value">
                Proyecto Almería (Servicios de limpieza)
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__tweet-content">
              <label class="twiter-panel--field__label">Nivel de suscripción:</label>
              <div class="twiter-panel--field__value">
                Elevated
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__link">
              <label class="twiter-panel--field__label">Link:</label>
              <div class="twiter-panel--field__value">
                <v-icon color="red accent-2">mdi-open-in-new</v-icon>
                <a class="red--text text--accent-2" target="_blank" href="https://developer.twitter.com/en/docs/twitter-api" rel="noopener">Twitter API v2</a>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__feeling">
              <label class="twiter-panel--field__label">Sentimientos:</label>
              <div class="twiter-panel--field__value">
                <div class="mdtec-card--header__actions">
                  <!-- vuetify chip with happy face icon -->
                  <v-chip color="teal accent-2" class="tweet-panel--header--feeling mr-3"
                    text-color="teal accent-5" dark>
                    <v-icon left>mdi-emoticon-happy</v-icon>
                    Positivo
                  </v-chip>
                  <v-chip color="orange accent-1" class="tweet-panel--header--feeling mr-3"
                    text-color="orange darken-3" dark>
                    <v-icon left>mdi-emoticon-neutral</v-icon>
                    Neutro
                  </v-chip>
                  <v-chip color="deep-purple accent-1" class="tweet-panel--header--feeling"
                    text-color="deep-purple darken-4" dark>
                    <v-icon left>mdi-emoticon-sad</v-icon>
                    Negativo
                  </v-chip>
                </div>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__hashtags">
              <label class="twiter-panel--field__label">Palabras clave:</label>
              <div class="twiter-panel--field__value">
                <v-chip v-for="(value, index) in keyWords" :key="index" color="grey lighten-1" small
                  light> {{ value }} </v-chip>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__hashtags">
              <label class="twiter-panel--field__label">Perfiles de usuario:</label>
              <div class="twiter-panel--field__value">
                <v-chip  v-for="(value, index) in keyUsers" :key="index" color="red accent-2" small
                  dark> {{ value }} </v-chip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// eslint-disable-next-line import/no-cycle

export default {
  name: 'TwitterSubscription',
  data() {
    return {
      id: null,
      user: null,
      items: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: '',
          disabled: false,
          to: '/carga-de-twitter',
        },
        {
          text: 'Suscripción',
          disabled: true,
          to: '#',
        },
      ],
      keyWords: ['acciona', 'sucio', 'suciedad', 'limpia', 'limpieza', 'basura', 'residuos', 'contenedor', 'papelera',
        'marranería', 'solares', 'acumulación', 'mancha', 'manchas', 'botellon', 'botellones', 'excrementos', 'cacas', 'pipis', 'meados', 'mierda', 'orines'],
      keyUsers: ['@AlmeriaSostenib'],
    };
  },
  mounted() {
    this.items[1].text = this.$store.state.project;
    // execute this script <script async src="https://platform.twitter.com/widgets.js" charset="utf-8">
  },
  computed: {
  },
  methods: {

  },
};

</script>
<style lang="scss" scoped>
.twitter-single {
  display: grid;
  grid-gap: 0.825rem;
  grid-template-columns: repeat(14, 1fr);
  grid-auto-rows: min-content;

  .twitter-single--tweet-panel {
    grid-column: 1 / span 8;
    // height:100%;
    max-height: calc(var(--app-height) - 8rem);
    padding-bottom: 0;

    .tweet-panel--header {

      .tweet-panel--header--feeling {
        margin-top: -0.5rem;
        margin-right: -0.5rem;
      }
    }

    .tweet-panel--body {
      height: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      gap: 1rem;

      &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
      }

      .field-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;

        .twiter-panel--field {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          font-size: 1rem;
          line-height: 1.5rem;
          gap: 0.5rem;
          width: 100%;

          &__label {
            font-weight: 500;
            min-width: 25%;
            color: #000;
            margin-bottom: 0;
          }

          &__value {
            font-weight: 400;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.5rem;
            color: rgba(0, 0, 0, 0.5);
          }

          &__date,
          &__user {
            gap: 0.25rem;
            width: auto;

            label {
              min-width: auto;
            }
          }
        }
      }

      .twitter-tweet {
        margin-top: 2rem;
      }
    }
  }

  .twitter-single--user-panel {
    grid-column: 6 / span 9;
    max-height: calc(var(--app-height) - 8rem);
    padding-bottom: 0;

    .user-panel--body {
      height: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      gap: 2rem;

      &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
      }

      .user-panel--body__profile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1rem;

        // margin-top:0.5rem;
        &__avatar {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          height: 100%;
          gap: 0.5rem;

          &__name {
            font-size: 1.375rem;
            line-height: 1rem;
            font-weight: 500;
            margin: 0;
            text-transform: capitalize;
          }

          &__username {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }

          &__description {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 400;
            font-style: italic;
          }

          &__stats {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0.5rem;

            &__item {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              gap: 0.25rem;

              &__label {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 500;
              }

              &__value {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.twitter-single .twitter-tweet.twitter-tweet-rendered {
  margin-bottom: 0 !important;
}
</style>
