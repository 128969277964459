<template>
  <div class="dashboard-main">
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :href="itemt.href" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="project-grid">
      <div
        class="project-card dashboard_box"
        v-for="option in options"
        :key="option.value"
      >
        <div class="project-thumbnail">
          <v-icon color="red ligthen-1" x-large>{{ option.icon }}</v-icon>
        </div>
        <div class="project-label">
          <div class="project-info">
            <div class="project-title">{{ option.text }}</div>
            <div class="project-description">{{ option.description }}</div>
          </div>
          <div class="project-button">
            <v-btn
              outlined
              color="red lighten-1"
              small
              @click="updateProject(option)"
              >Seleccionar</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'select-project',
  data: () => ({
    project: null,
    items: [
      {
        text: 'Proyectos',
        disabled: true,
        href: '#',
      },
    ],
    options: [],
    info: null,
    loading: true,
    errored: false,
    userId: null,
    userRole: null,
    userName: null,
    user: [],
  }),
  components: {},
  mounted() {
    this.getProjects();
  },
  methods: {
    updateProject(val) {
      this.project = val;
      this.$store.commit('setProject', val);
      this.setProjectSelected();
    },
    setProjectSelected() {
      if (this.$store.state.project === 'Almería') {
        this.$router.push('/carga-de-twitter');
      } else if (this.$store.state.project === 'Licitaciones Agua') {
        this.$router.push('/dashboard');
      }
    },
    getProjects() {
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/user-projects`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.options = response.data.map((ele) => ({
            value: ele.id,
            text: ele.Nombre,
            description: ele.descripcion,
            icon: ele.icono,
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
// * {
//   border: solid 1px green;
// }
.project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  .project-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column: span 1;
    position: relative;

    .project-thumbnail {
      width: 100%;
      height: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fee6e6;
      .v-icon{
        transform: scale(2);
      }
    }
    .project-label {
      padding: 2em;
      display: flex;
      padding-bottom: 0;
      flex-direction: column;
      align-items: flex-start;

      .project-info {
        width: 80%;
        margin-bottom: 2em;
        .project-title {
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 1em;
          margin-top: 1em;
        }
        .project-description {
          font-size: 14px;
          line-height: 1.2em;
          max-width: 400px;
        }
      }
      .project-button {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 2em;
      }
    }
  }
}
</style>
