<template>
  <div>
    <md-progress-bar
      class="mm-progress-bar"
      md-mode="indeterminate"
      v-if="loading"
    />
    <md-table
      v-model="items"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      :md-sort-fn="customSort"
      class="dashboard_box table-box"
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell
          md-label="Usuario"
          md-sort-by="userModel.username"
          class="table-description"
          >{{ item.userModel.username }}</md-table-cell
        >
        <md-table-cell
          md-label="Acción"
          md-sort-by="action"
          class="table-description"
          >{{ item.action }}</md-table-cell
        >
        <md-table-cell
          md-label="Fecha"
          md-sort-by="creationDate"
          class="table-description"
          >{{ item.creationDate }}</md-table-cell
        >
      </md-table-row>
    </md-table>
    <div class="pagination-container">
      <div class="pagination-options" v-if="!loading">
        Elementos por página:
        <select aria-labelledby="select-page"
        v-model="pageSize" @blur="handlePageSizeChange($event)" >
          <option
          v-for="size in pageSizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
        <div style="float: right">Total de elementos: {{ this.count }}</div>
      </div>
      <div class="pagination-wrapper">
        <b-pagination
          v-if="!loading"
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          prev-text="Anterior"
          next-text="Siguiente"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

export default {
  name: 'log-standard-table',
  props: {
    username: {
      type: String,
      default: null,
    },
    role: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 10,
      pageSizes: [5, 10, 20],

      currentSort: 'creationDate',
      currentSortOrder: 'desc',
      loading: true,
    };
  },
  created() {
    this.getItems();
  },

  watch: {
    username() {
      this.getItems();
    },
    role() {
      this.getItems();
    },
    action() {
      this.getItems();
    },
    currentSort() {
      this.getItems();
    },
    currentSortOrder() {
      this.getItems();
    },
  },
  methods: {
    getRequestParams(page, pageSize) {
      const params = {};
      params.username = this.username;
      params.role = this.role;
      params.action = this.action;
      params.sort = this.currentSort;
      params.direction = this.currentSortOrder;

      if (page) {
        params.page = page - 1;
      }

      if (pageSize) {
        params.size = pageSize;
      }

      return params;
    },

    getItems() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}log/filtered/`, {
          headers: authHeader(),
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.items = response.data.content;
          this.count = response.data.totalElements;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlePageChange(value) {
      this.page = value;
      this.getItems();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.getItems();
    },

    customSort() {
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.table-description {
  line-height: 1.3;
  padding: 0.5em !important;
}
.table-box {
  border-left: solid 1px #e5e5e5 !important;
  // margin-bottom: 1em;
}
.pagination-container {
  display: flex;
  flex-direction: column;
}
.pagination-options {
  margin-top: 1em;
  margin-bottom: 1em;
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    width: max-content;
  }
}
</style>
