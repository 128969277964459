<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="custom-modal" v-if="dialog">
    <div class="custom-modal--inner">
      <div class="custom-modal--header">
        <div class="custom-modal--header-title">
          <slot name="header-title"></slot>
        </div>
        <div class="custom-modal--header-close">
          <v-icon @click="closeModal()">mdi-close</v-icon>
        </div>
      </div>
      <div class="custom-modal--body">
        <slot name="body"></slot>
      </div>
      <div class="custom-modal--footer">
        <slot name="footer"></slot>
      </div>
      <div class="custom-modal--actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomModal',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    openModal() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  // background:rgba(255,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  .custom-modal--inner {
    position: relative;
    width: auto;
    // min-width: 440px;
    // max-width: 768px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    z-index: 200;

    .custom-modal--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      .custom-modal--header-title {
        font-size: 1rem;
        line-height: 2rem;
        max-width: 90%;
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.2rem;
          line-height: 2rem;
          margin: 0;
        }
      }
      .custom-modal--header-close {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .custom-modal--body {
      padding: 1rem;
      display: flex;
      justify-content: center;
    }
    .custom-modal--footer {
      padding: 1rem;
    }
    .custom-modal--actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem;
    }
  }
}
</style>
