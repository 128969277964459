<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="dashboard-main twitter-stats">
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="twitter-load--filter twelve-col">
      <div class="dashboard-box small-box twitter-load--filter--date">
        <div class="dashboard-box--label twitter-load--filter--date__label">
          <h2><v-icon x-small>mdi-calendar</v-icon> Rango de fechas</h2>
        </div>
        <div class="dashboard-box--content twitter-load--filter--date__content">
          <v-btn icon @click="pastWeek()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ dateRangeText }}
          <v-btn icon @click="nextWeek()" :disabled="this.week > 1 ? false : true">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="twitter-load--insights twelve-col">
      <div class="dashboard-box twitter-load--insights__total insight-box">
        <div class="dashboard-box--label">
          <h2><v-icon x-small>mdi-twitter</v-icon>Total de Tweets:</h2>
        </div>
        <div class="dashboard-box--content insights--total">
          <span class="insights--total__value">{{totalWithSentiment}}</span>
          <span class="insights--total__label">tweets</span>
        </div>
      </div>
      <div class="dashboard-box twitter-load--insights__positive insight-box">
        <div class="dashboard-box--label">
          <h2>
            <v-icon x-small color="teal accent-3">mdi-emoticon-happy</v-icon
            >Tweets positivos
          </h2>
        </div>
        <div
          class="
            dashboard-box--content
            insights--positive
            insights-box--content
          "
        >
          <div
            class="
              insights--positive--wrapper
              insights-box--content--wrapper
              positive
            "
          >
            <span
              class="
                insights--positive__value
                insights-box--content--wrapper__value
              "
              >{{totalPositive}}</span
            >
            <span
              class="
                insights--positive__label
                insights-box--content--wrapper__label
              "
              >tweets</span
            >
          </div>
          <div class="insights--positive__chip insights-filter-chip">
            <v-chip color="teal accent-3" text-color="teal darken-4" pill small
              >{{getPercentage(totalPositive)}}%</v-chip
            >
          </div>
        </div>
      </div>
      <div class="dashboard-box twitter-load--insights__negative insight-box">
        <div class="dashboard-box--label">
          <h2>
            <v-icon x-small color="deep-purple accent-3"
              >mdi-emoticon-sad</v-icon
            >Tweets negativos
          </h2>
        </div>
        <div
          class="
            dashboard-box--content
            insights--negative
            insights-box--content
          "
        >
          <div
            class="
              insights--negative--wrapper
              insights-box--content--wrapper
              negative
            "
          >
            <span
              class="
                insights--negative__value
                insights-box--content--wrapper__value
              "
              >{{totalNegative}}</span
            >
            <span
              class="
                insights--negative__label
                insights-box--content--wrapper__label
              "
              >tweets</span
            >
          </div>
          <div class="insights--negative__chip insights-filter-chip">
            <v-chip
              color="deep-purple accent-3"
              text-color="deep-purple lighten-5"
              pill
              small
              >{{getPercentage(totalNegative)}}%</v-chip
            >
          </div>
        </div>
      </div>
      <div class="dashboard-box twitter-load--insights__neutral insight-box">
        <div class="dashboard-box--label">
          <h2>
            <v-icon x-small color="orange accent-3">mdi-emoticon-neutral</v-icon
            >Tweets neutros
          </h2>
        </div>
        <div
          class="dashboard-box--content insights--neutral insights-box--content"
        >
          <div
            class="
              insights--neutral--wrapper
              insights-box--content--wrapper
              neutral
            "
          >
            <span
              class="
                insights--negative__value
                insights-box--content--wrapper__value
              "
              >{{totalNeutral}}</span
            >
            <span
              class="
                insights--neutral__label
                insights-box--content--wrapper__label
              "
              >tweets</span
            >
          </div>
          <div class="insights--neutral__chip insights-filter-chip">
            <v-chip
              color="orange accent-3"
              text-color="orange lighten-5"
              pill
              small
              >{{getPercentage(totalNeutral)}}%</v-chip
            >
          </div>
        </div>
      </div>
    </div>
    <div class="charts-grid">
      <div class="chart-noticias">
        <div class="dashboard_box chart" id="HistoricChart">
          <time-chart ref="timeChart"></time-chart>
        </div>
      </div>
      <div class="chart-evaluadas">
        <div class="dashboard_box chart" id="chartInscription">
          <LabeledChart ref="labeledChart" containerId="pieChartId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import tweetService from '@/services/tweet.service';
/* eslint-disable max-len */
import TimeChart from '@/components/Charts/TimeLineChart.vue';
import LabeledChart from '@/components/Charts/SentimentChart.vue';

import authService from '@/services/auth.service';

export default {
  name: 'twitter-stats',
  components: {
    TimeChart,
    LabeledChart,
  },
  data() {
    return {

      dates: null,
      loading: false,
      items: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: '',
          disabled: false,
          to: '/carga-de-twitter',
        },
        {
          text: 'Estadísticas',
          disabled: true,
          to: '#',
        },
      ],

      labeledData: {
        totalPositive: 0,
        totalNegative: 0,
        totalNeutral: 0,
      },
      filter: {
        from: null,
        to: null,
        sentiments: '1,2,3,positive,negative,neutral',
      },
      userId: null,
      userRole: null,
      userName: null,
      user: [],

      // Start and end dates
      totalNegative: 0,
      totalNeutral: 0,
      totalPositive: 0,
      totalWithSentiment: 0,
      chartData: {
        labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        datasets: [
          {
            label: 'Positivos',
            borderColor: '#1DE9B6',
            backgroundColor: '#1DE9B6',
            data: null,
          },
          {
            label: 'Negativos',
            borderColor: '#651FFF',
            backgroundColor: '#651FFF',
            data: null,
          },
          {
            label: 'Neutros',
            borderColor: '#FF9100',
            backgroundColor: '#FF9100',
            data: null,
          },
        ],
      },
      week: 1,
    };
  },
  computed: {
    dateRangeText() {
      if (this.dates) {
        const date0 = new Date(this.dates[0]);
        const date1 = new Date(this.dates[1]);

        // return dates in format: 16 Jun 2022 - 23 Jun 2023
        return `${date0.toLocaleDateString('es-ES', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })} - ${date1.toLocaleDateString('es-ES', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      }

      return null;
    },
  },

  mounted() {
    if (this.$store.state.project != null) {
      this.items[1].text = this.$store.state.project;
      this.userId = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).affiliate;
      this.userRole = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).rol;
      this.userName = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).sub;
      this.dates = [tweetService.getDayXDaysAgo(7), tweetService.getToday()];
      this.setDatesFilter();
      this.loadData();
    } else {
      this.$router.push('/seleccionar-proyecto');
    }
  },
  methods: {
    loadData() {
      this.loadSentimentData();
      this.getTimeStats();
    },
    pastWeek() {
      this.week += 1;
      this.dates = [tweetService.getDayXDaysAgo(7 * this.week), tweetService.getDayXDaysAgo(7 * (this.week - 1))];
      this.setDatesFilter();
      this.loadData();
    },
    nextWeek() {
      if (this.week > 1) {
        this.week -= 1;
        this.dates = [tweetService.getDayXDaysAgo(7 * this.week), tweetService.getDayXDaysAgo(7 * (this.week - 1))];
        this.setDatesFilter();
        this.loadData();
      }
    },
    getRequestParams() {
      const params = {};
      params.projectId = this.$store.state.projectId;
      return params;
    },
    setStatsSentiment(response) {
      this.totalPositive = response.totalPositive;
      this.totalNegative = response.totalNegative;
      this.totalNeutral = response.totalNeutral;
      this.totalWithSentiment = this.totalPositive + this.totalNegative + this.totalNeutral;
    },

    loadSentimentData() {
      tweetService.getStats(this.parseStringToDateWith00MonthAndDay(this.filter.from), this.parseStringToDateWith00MonthAndDay(this.filter.to))
        .then((response) => {
          this.setStatsSentiment(response);
          this.labeledData.totalPositive = response.totalPositive;
          this.labeledData.totalNegative = response.totalNegative;
          this.labeledData.totalNeutral = response.totalNeutral;
          this.$refs.labeledChart.updateLabeledData(this.labeledData);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setDatesFilter() {
      // eslint-disable-next-line prefer-destructuring
      this.filter.from = this.dates[0];
      // eslint-disable-next-line prefer-destructuring
      this.filter.to = tweetService.add1Day(this.dates[1]);
    },
    resetFilters() {
      this.dates = [tweetService.getDayXDaysAgo(7), tweetService.getToday()];
      this.positivos = true;
      this.negativos = true;
      this.neutros = true;
      this.setDatesFilter();
    },
    getPercentage(value) {
      if (this.totalWithSentiment === 0) {
        return 0;
      }
      return Math.round((value * 100) / this.totalWithSentiment);
    },
    async getDayStats(dateStart, dateEnd, index) {
      await tweetService.getStats(this.parseStringToDateWith00MonthAndDay(dateStart), this.parseStringToDateWith00MonthAndDay(dateEnd))
        .then((res) => {
          this.$set(this.chartData.datasets[0].data, index, this.chartData.datasets[0].data[index] + res.totalPositive);
          this.$set(this.chartData.datasets[1].data, index, this.chartData.datasets[1].data[index] + res.totalNegative);
          this.$set(this.chartData.datasets[2].data, index, this.chartData.datasets[2].data[index] + res.totalNeutral);
        });
    },
    parseStringToDateWith00MonthAndDay(date) {
      let newDate = new Date(date);
      const orgDate = new Date(date);
      if (orgDate.getMonth() < 10 && orgDate.getDate() < 10) {
        newDate = `${orgDate.getFullYear()}-0${orgDate.getMonth() + 1}-0${orgDate.getDate()}`;
      } else if (orgDate.getMonth() < 10) {
        newDate = `${orgDate.getFullYear()}-0${orgDate.getMonth() + 1}-${orgDate.getDate()}`;
      } else if (orgDate.getDate() < 10) {
        newDate = `${orgDate.getFullYear()}-${orgDate.getMonth() + 1}-0${orgDate.getDate()}`;
      } else {
        newDate = `${orgDate.getFullYear()}-${orgDate.getMonth() + 1}-${orgDate.getDate()}`;
      }
      return newDate;
    },
    setLabels() {
      this.chartData.labels = tweetService.getIntervalOfDays(7, 7 * (this.week - 1));

      this.chartData.datasets[0].data = Array(this.chartData.labels.length).fill(0);
      this.chartData.datasets[1].data = Array(this.chartData.labels.length).fill(0);
      this.chartData.datasets[2].data = Array(this.chartData.labels.length).fill(0);
    },
    getTimeStats() {
      this.loading = true;
      this.setLabels();
      // create a promise that resolves when all calls to getProjectStats() are complete
      const promise = new Promise((resolve) => {
        let completed = 0;
        // for every element in chartData.labels, get stats
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.chartData.labels.length; i++) {
          // get stats for each day
          if (i === this.chartData.labels.length - 1) {
            // if last day, get stats from day to tomorrow
            // eslint-disable-next-line no-loop-func
            this.getDayStats(this.chartData.labels[i], tweetService.add1Day(this.chartData.labels[i]), i).then(() => {
              completed += 1;
              if (completed === this.chartData.labels.length) {
                resolve();
              }
            });
            break;
          }
          // eslint-disable-next-line no-loop-func
          this.getDayStats(this.chartData.labels[i], this.chartData.labels[i + 1], i).then(() => {
            completed += 1;
            // if all calls to getProjectStats() are complete, resolve the promise
            if (completed === this.chartData.labels.length) {
              resolve();
            }
          });
        }
      });
      // when the promise is resolved, update the chart with the new values
      promise.then(() => {
        this.loading = false;
        this.$refs.timeChart.updateValues(this.chartData);
      });
    },

  },
};
</script>

<style scoped>
.item {
  margin-bottom: 2rem;
}
.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}
.card-filter-button:hover {
  background-color: #fff;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}
</style>
<style lang="scss" scoped>
.twitter-load--filter {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.875rem;
  height: 4.5rem;
  margin-bottom: 0.875rem;
  .twitter-load--filter--date {
    padding: 0.5rem;
    padding-right: 0.875rem;
    width: 28.57142857%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .twitter-load--filter--radio-buttons {
    padding: 1.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    width: 71.42857143%;
    &__label {
      display: flex;
      align-items: center;
      h2 {
        font-size: 1.125rem;
        margin-bottom: 0;
      }
    }
    &__checkboxes {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 1rem;
      width: 100%;
      &__reset {
        margin-left: auto;
      }
    }
  }
}
.twitter-load--insights {
  display: flex;
  flex-wrap: nowrap;
  height: 7rem;
  gap: 0.875rem;
  margin-bottom: 0.875rem;
  .insight-box {
    padding: 0.5rem;
    padding-right: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .insights--total {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 0.2rem;
    &__value {
      font-size: 4rem;
      line-height: 1;
      font-weight: 500;
      margin: 0;
    }
    &__label {
      font-size: 1.5rem;
      margin: 0;
    }
  }
  .insights-box--content {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    .insights-box--content--wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      gap: 0.2rem;
      margin-bottom: 0.2rem;
      &__value {
        font-size: 2rem;
        line-height: 1;
        font-weight: 500;
        margin: 0;
      }
      &__label {
        font-size: 0.75rem;
        margin: 0;
      }
      &.positive {
        color: #1de9b6;
      }
      &.negative {
        color: #651fff;
      }
      &.neutral {
        color: #ff9100;
      }
    }
  }
  .twitter-load--insights__total {
    width: 35.71428571%;
  }
  .twitter-load--insights__positive,
  .twitter-load--insights__negative,
  .twitter-load--insights__neutral {
    width: 21.42857143%;
  }
}
.dashboard-box--label {
  h2 {
    font-size: 0.75rem;
    margin-bottom: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.15rem;
  }
}
.charts-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;
  height: 100%;
}
.dashboard_box.tabs {
  margin-bottom: 1em;
}

.noticias-box {
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;

  .noticias-total {
    margin-top: 0.5em;
    font-size: 1.71em;
    color: #e57373;
  }
  .noticias-text {
    line-height: 1.1;
    // max-width: 8em;
    text-align: center;
  }
}
.chart-noticias {
  grid-column: 1 / span 8;
  height: 100%;

  .chart {
    padding: 1em;
  }
}
.chart-evaluadas {
  grid-column: 9 / span 4;
  height: 100%;

  .chart {
    padding: 2em;
    height: 100%;
  }
}
.cantidad-noticias-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1em;
  & > div {
    grid-column: span 4;
    min-height: 150px;
  }
  // flex-direction: column;
  // justify-content: space-between;
  // align-content:space-between;
}
// * {
//   border: solid 1px blue;
// }
</style>
<style lang="scss">
.twitter-stats {
  & .v-picker.v-card.v-picker--date.theme--light {
    position: static;
  }
  &
    .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox {
    padding-top: 0;
  }
  &
    .v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
    padding-top: 0;
  }
}
</style>
