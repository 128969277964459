<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Proyecto</th>
          <th class="text-left">Descripción</th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in projects" :key="item.text">
          <td class="table-description">{{ item.text }}</td>
          <td class="table-description">{{ item.description }}</td>
          <td>
            <div class="project-erase">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.deleteHide"
                    fab
                    color="rgba(0,0,0,0.56)"
                    text
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="item.deleteHide = false"
                    ><v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar proyecto</span>
              </v-tooltip>
              <v-btn
                v-show="!item.deleteHide"
                fab
                color="rgba(0,0,0,0.56)"
                text
                small
                @click="deleteProjectItems(item.id)"
                ><v-icon color="teal accent-3">mdi-check-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-show="!item.deleteHide"
                fab
                color="rgba(0,0,0,0.56)"
                text
                small
                @click="item.deleteHide = true"
                ><v-icon color="red lighten-1">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'projects-table',
  data() {
    return {
      currentIndex: -1,
      searchTitle: '',
      count: 0,
      username: null,
      projects: [],
    };
  },

  methods: {
    updateUsername(val) {
      this.username = val;
      this.getProjectItems();
    },
    getRequestParams() {
      const params = {};
      return params;
    },

    getProjectItems() {
      if (this.username) {
        axios
          .get(`${process.env.VUE_APP_API_URL}analizados/user-projects/${this.username}`, {
            headers: authHeader(),
            params: this.getRequestParams(this.page, this.pageSize),
          })
          .then((response) => {
            this.projects = response.data.map((ele) => ({
              id: ele.id,
              text: ele.nombre,
              description: ele.descripcion,
              icon: ele.icono,
              deleteHide: true,
            }));
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    deleteProjectItems(projId) {
      axios
        .delete(`${process.env.VUE_APP_API_URL}projectPermissions/${projId}`, {
          headers: authHeader(),
        })
        .then(() => {
          // refresh router view
          this.$router.go();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-description {
  line-height: 1.3;
  padding: 1em !important;
}
.project-erase {
  display: flex;
  flex-wrap: nowrap;

}
</style>
