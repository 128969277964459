<template>
  <div>
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <Edit-user
    title="Añadir Usuario"
    icon="mdi-plus">
    </Edit-user>
    <div class="users_panel-grid">
      <div class="left-side">
        <v-tabs
          background-color="transparent"
          color="red lighten-1"
          class="dashboard_box users_panel-tab"
          grow
        >
          <v-tab> Panel de Usuarios </v-tab>
        </v-tabs>
        <div class="users_panel-filters">
          <div class="filters-text">Filtrar por:</div>
          <v-text-field
            dense
            label="Usuario"
            color="red lighten-1"
            placeholder="email"
            outlined
            v-model="filter.username"
          ></v-text-field>
          <v-select
            dense
            :items="RoleOptions"
            outlined
            label="Rol"
            color="red lighten-1"
            v-model="filter.role"
          ></v-select>
          <v-btn text color="rgba(0,0,0,0.6)" @click="resetFilter()">
            <v-icon class="mr-2"> mdi-close</v-icon>Borrar Filtros</v-btn
          >
        </div>
        <!-- <v-divider></v-divider> -->
        <div class="users_panel-table">
          <users
            @emitCount="updateTotalUsers"
            :username="filter.username"
            :role="filter.role"
          ></users>
        </div>
      </div>
      <div class="right-side">
        <!-- <v-tabs
          background-color="transparent"
          color="red lighten-1"
          class="dashboard_box users_panel-tab"
          grow
        >
          <v-tab> Roles </v-tab>
        </v-tabs> -->
        <div class="chart_box">
          <div class="usuarios-totales dashboard_box">
            <v-icon
              color="red lighten-1"
              class="usuarios-totale-icon mr-2"
              large
              >mdi-account-group</v-icon
            >
            <span class="usuarios-totales-text">
              Total de usuarios: {{ totalUsers }}
            </span>
          </div>
          <div class="usuarios-chart dashboard_box">
            <RoleChart ref="roleChart" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import authHeader from '@/services/auth-header';
import Users from '../../components/Tables/Users.vue';
import RoleChart from '../../components/Charts/RoleChart.vue';
import EditUser from '../../components/Forms/EditUser.vue';

const axios = require('axios');

export default {
  name: 'users-manager',
  data() {
    return {
      totalUsers: null,
      filter: {
        username: null,
        role: null,
      },
      breadcrumbs: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: 'Panel de usuarios',
          disabled: false,
          to: '/gestion-usuarios',
        },
        {
          text: '',
          disabled: true,
          to: '#',
        },
      ],
      roleData: {
        totalUser: 0,
        totalEnlace: 0,
        totalResponsable: 0,
        totalAdmin: 0,
        totalSuperAdmin: 0,
      },
      RoleOptions: [
        { value: 0, text: 'ROLE_USER' },
        { value: 4, text: 'ROLE_SUPER_ADMIN' },
      ],
    };
  },
  components: {
    EditUser,
    Users,
    RoleChart,
  },
  created() {
    this.loadRoleData();
  },
  methods: {
    updateTotalUsers(val) {
      this.totalUsers = val;
    },
    resetFilter() {
      this.filter.username = null;
      this.filter.role = null;
    },
    loadRoleData() {
      axios
        .get(`${process.env.VUE_APP_API_URL}stats/role/`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.roleData.totalUser = 0;
          this.roleData.totalEnlace = 0;
          this.roleData.totalResponsable = 0;
          this.roleData.totalAdmin = 0;
          this.roleData.totalSuperAdmin = 0;

          response.data.forEach((element) => {
            const clave = element[0];
            const valor = element[1];
            if (clave === 'ROLE_USER') {
              this.roleData.totalUser = valor;
            } else if (clave === 'ROLE_ENLACE') {
              this.roleData.totalEnlace = valor;
            } else if (clave === 'ROLE_RESPONSABLE') {
              this.roleData.totalResponsable = valor;
            } else if (clave === 'ROLE_ADMIN') {
              this.roleData.totalAdmin = valor;
            } else if (clave === 'ROLE_SUPER_ADMIN') {
              this.roleData.totalSuperAdmin = valor;
            }
          });

          this.$refs.roleChart.updateRoleData(this.roleData);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.users_panel-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;
  .left-side {
    grid-column: 1 / span 8;
  }
  .right-side {
    grid-column: 9 / span 4;
  }
}
.users_panel-tab {
  margin-bottom: 1em;
  pointer-events: none;
}
.users_panel-filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  .filters-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #ef5350;
    margin-right: 1em;
  }
}
.usuarios-totales {
  height: calc(3em + 3px);
  color: #ef5350;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  // margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
  .usuarios-totales-text {
    font-size: 20px;
  }
}
</style>
<style lang="scss">

.users_panel-filters .v-input__slot {
  margin-bottom: 0 !important;
}
.users_panel-filters .v-input {
  margin-right: 1em;
}
</style>
