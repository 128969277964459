<template>
  <div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear
        color="red lighten-1"
        class="linear-progress"
        indeterminate
      ></v-progress-linear>
    </div>
    <md-table
      v-model="users"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      :md-sort-fn="customSort"
      class="dashboard_box table_box"
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell class="no-padding justify-center">
          <md-avatar class="md-avatar-icon md-small">
            {{ item.username.substring(1, 0).toUpperCase() }}
            </md-avatar>
        </md-table-cell>
        <md-table-cell md-label="Usuario" md-sort-by="username"
          >
          {{ item.username }}
        </md-table-cell>
        <md-table-cell md-label="Rol" md-sort-by="role"
          > {{ item.role }}
        </md-table-cell>
        <md-table-cell md-label="Nº de proyectos"
          > {{ getProjectsCount(item.projects) }}
        </md-table-cell>
        <md-table-cell md-label="Acciones" class="justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              fab
                color="rgba(0,0,0,0.56)"
                text
                small
                v-bind="attrs"
                v-on="on"
                @click="goToUserActivityView(item.id)"
              >
                <v-icon>mdi-account-clock-outline</v-icon>
              </v-btn>
            </template>
            <span>Actividad del usuario</span>
          </v-tooltip>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              fab
                color="rgba(0,0,0,0.56)"
                text
                small
                v-bind="attrs"
                v-on="on"
                @click="goToUserActivityView(item.id)"
              >
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>Editar usuario</span>
          </v-tooltip> -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
          <v-btn
                v-show="item.deleteHide"
                fab
                color="rgba(0,0,0,0.56)"
                text
                small
                v-bind="attrs"
                v-on="on"
                @click="item.deleteHide = false"
              ><v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          </template>
          <span>Eliminar usuario</span>
          </v-tooltip>
          <v-btn
                fab
                v-show="!item.deleteHide"
                color="rgba(0,0,0,0.56)"
                text
                small
                @click="unregisterUser(item.username)"
              ><v-icon color="teal accent-3">mdi-check-circle-outline</v-icon>
          </v-btn>
          <v-btn
                fab
                v-show="!item.deleteHide"
                color="rgba(0,0,0,0.56)"
                text
                small
                @click="item.deleteHide = true"
              ><v-icon color="red lighten-1">mdi-close-circle-outline</v-icon>
          </v-btn>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <div class="pagination-container">
      <div class="pagination-options" v-if="!loading">
        Elementos por página:
        <select aria-labelledby="select-page"
         v-model="pageSize" @blur="handlePageSizeChange($event)">
          <option v-for="size in pageSizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
        <div style="float: right">Total de usuarios: {{ this.count }}</div>
      </div>
      <div class="pagination-wrapper">
        <b-pagination
          v-if="!loading"
          v-model="page"
          :total-rows="count"
          :per-page="pageSize"
          prev-text="Anterior"
          next-text="Siguiente"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'users-table',
  props: {
    username: {
      type: String,
      default: null,
    },
    role: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 5,
      pageSizes: [5, 10, 20],
      loading: true,
      users: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
    };
  },
  watch: {
    role() {
      this.getUserItems();
    },
    username() {
      this.getUserItems();
    },
    currentSort() {
      console.log('change on Sort');
    },
    currentSortOrder() {
      console.log('change on direction');
    },
  },
  created() {
    this.getUserItems();
  },
  methods: {
    getProjectsCount(val) {
      if (val) {
        return val.length;
      }
      return 0;
    },
    goToUserView(id) {
      return this.$router.push(`/inscritos/${id}`);
    },
    goToUserActivityView(id) {
      return this.$router.push(`/actividad-de-usuario/${id}`);
    },
    emitCount() {
      this.$emit('emitCount', this.count);
    },

    getRequestParams(page, pageSize) {
      const params = {};
      params.username = this.username;
      params.role = this.role;

      if (page) {
        params.page = page - 1;
      }

      if (pageSize) {
        params.size = pageSize;
      }
      return params;
    },

    handlePageChange(value) {
      this.page = value;
      this.getUserItems();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.getUserItems();
    },
    getUserItems() {
      axios
        .get(`${process.env.VUE_APP_API_URL}users/filtered/`, {
          headers: authHeader(),
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.users = response.data.content.map((ele) => ({
            id: ele.id,
            username: ele.username,
            role: ele.role,
            projects: ele.projects,
            deleteHide: true,
          }));

          this.count = response.data.totalElements;
          this.emitCount();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    unregisterUser(userselected) {
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}unregister/`,
          {
            username: userselected,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.loading = false;
          this.getUserItems();
        });
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy]);
        }

        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table_box{
  min-height: 150px;
  overflow: hidden;
  border-left:solid 1px #e5e5e5 !important;
}
</style>
<style lang="scss" >
.justify-center{
  .md-table-cell-container{
  justify-content: center !important;
}
}
.no-padding{
  .md-avatar{
    margin: 0 0.5em;
  }
  .md-table-cell-container{
    padding-left:0;
    padding-right:0;
  }
}
.pagination-options{
  padding-right: 0.1em;
}

.loader-overlay{
  position: relative;
}
</style>
