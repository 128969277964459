/* eslint-disable class-methods-use-this */
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class TweetService {
  getAllTweets(params) {
    return axios
      .get(`${API_URL}tweet/all`, { params, headers: authHeader() })
      .then((response) => response.data);
  }

  getTweetsFiltered(params) {
    return axios
      .get(`${API_URL}tweet/filtered`, { params, headers: authHeader() })
      .then((response) => response.data);
  }

  getTweet(id) {
    return axios
      .get(`${API_URL}tweet/${id}`, { headers: authHeader() })
      .then((response) => response.data);
  }

  deleteTweet(id) {
    return axios
      .delete(`${API_URL}tweet/${id}`, { headers: authHeader() })
      .then((response) => response.data);
  }

  getTweetByOriginalId(id) {
    return axios
      .get(`${API_URL}tweet/original-id/${id}`, { headers: authHeader() })
      .then((response) => response.data);
  }

  getStats(dateStart, dateEnd) {
    const params = {
      from: dateStart,
      to: dateEnd,
    };
    return axios
      .get(`${API_URL}tweet/stats`, { params, headers: authHeader() })
      .then((response) => response.data);
  }

  /// /////////////////
  // UTILS //
  /// /////////////////

  getLastDays(days) {
    const date = new Date();
    const labels = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < days; i++) {
      labels.push(this.getDay(date));
      date.setDate(date.getDate() - 1);
    }
    return labels.reverse();
  }

  getIntervalOfDays(days, daysAgo) {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    const labels = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < days; i++) {
      labels.push(this.getDay(date));
      date.setDate(date.getDate() - 1);
    }
    return labels.reverse();
  }

  getDay(date) {
    // get day in format 2022-12-01
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  getDayXDaysAgo(days) {
    // in format 2022-12-01
    const date = new Date();
    date.setDate(date.getDate() - days);
    return this.getDay(date);
  }

  getToday() {
    // in format 2022-12-01
    const date = new Date();
    return this.getDay(date);
  }

  getDayTomorrow() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  add1Day(date) {
    // in format 2022-12-01
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return this.getDay(newDate);
  }
}

export default new TweetService();
