<template>
  <div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear color="red lighten-1"
       class="linear-progress" indeterminate></v-progress-linear>
    </div>
    <!-- Add button to change table/tweet view
    <div class="d-flex justify-space-between">
      <div>
      </div>
      <div class="mb-2">
        <v-btn class="mx-2" elevation="0" fab dark small
        :color="this.tableView ? 'grey' : 'red accent-2'"
        @click="setTableView(false)">
          <v-icon dark>
            mdi-twitter
          </v-icon>
        </v-btn>
        <v-btn class="mx-2" elevation="0" fab dark small
        :color="this.tableView ? 'red accent-2' : 'grey'"
        @click="setTableView(true)">
          <v-icon dark>
            mdi-table
          </v-icon>
        </v-btn>
      </div>
    </div>-->

    <!-- Table view -->
    <md-table v-show="tableView & !loading" v-model="tweets"
     :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
      @md-selected="onSelect" :md-sort-fn="customSort" class="dashboard_box table_box">
      <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
        <md-table-cell md-label="">
          <div style="display: inline-flex">
            <md-button class="md-icon-button md-dense"
             @click="goToTweetView(item.originalId, item.user.username)">
              <v-icon>mdi-twitter</v-icon>
            </md-button>
          </div>
        </md-table-cell>
        <!-- created At in style 15/12/2022 10:20 -->
        <md-table-cell md-label="Fecha" md-sort-by="date">
          {{ new Date(item.date).toISOString().slice(0, 10).replace(/-/g, "/") }}
        </md-table-cell>
        <!-- date in style Hace 10 min -->
        <md-table-cell md-label=""
        @click.self="goToTweetView(item.originalId, item.user.username)">
          {{ getSinceTime(item.date) }}
        </md-table-cell>
        <md-table-cell md-label="">
          <v-chip v-if="checkIfIsRetweet(item.text)"
          dark x-small class="ma-1" color="light-blue accent-3">
            retweet
          </v-chip>
        </md-table-cell>
        <md-table-cell md-label="Texto" md-sort-by="text" class="max-content-column">
          {{ item.text }}
        </md-table-cell>
        <md-table-cell md-label="">
          <v-avatar>
            <img :src="item.user.profileImageUrl" :alt="item.user.username">
          </v-avatar>
        </md-table-cell>
        <md-table-cell md-label="Usuario" md-sort-by="user.username">
          {{ item.user.username }}
        </md-table-cell>
        <md-table-cell md-label="Likes" md-sort-by="likeCount" :md-numeric="false">
          <v-chip small class="ma-1" :color="item.likeCount>=10 ? '#FF3855' : 'grey'"
             text-color="white" >
            <v-avatar left>
              <v-icon small>mdi-heart</v-icon>
            </v-avatar>
            {{ item.likeCount }}
          </v-chip>
        </md-table-cell>
        <md-table-cell md-label="Retweets" md-sort-by="retweetCount">
          <v-chip small class="ma-1" :color="item.retweetCount>=10 ? 'light-blue accent-3' : 'grey'"
             text-color="white" >
            <v-avatar left>
              <v-icon small>mdi-repeat-variant</v-icon>
            </v-avatar>
            {{ item.retweetCount }}
          </v-chip>
        </md-table-cell>
        <md-table-cell md-label="Ubicación del usuario" md-sort-by="user.location">
          {{ item.user.location }}
        </md-table-cell>
        <md-table-cell md-label="Alias" md-sort-by="user.name">
          {{ item.user.name }}
        </md-table-cell>
        <md-table-cell md-label="Sentimiento" md-sort-by="sentiment">
          <!-- v-icon -->
          <v-icon color="deep-purple accent-3"
          v-if="(item.sentiment === '1' || item.sentiment === 'negative')">
            mdi-emoticon-sad
          </v-icon>
          <v-icon color="orange accent-3"
          v-if="(item.sentiment === '2' || item.sentiment === 'neutral')">
            mdi-emoticon-neutral
          </v-icon>
          <v-icon color="teal accent-3"
          v-if="(item.sentiment === '3' || item.sentiment === 'positive')">
            mdi-emoticon-happy
          </v-icon>
        </md-table-cell>
        <md-table-cell md-label="" class="inherit-flex-flow justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="item.deleteHide" fab
              color="rgba(0,0,0,0.56)" text small v-bind="attrs" v-on="on"
                @click="item.deleteHide = false"><v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Eliminar tweet</span>
          </v-tooltip>
          <v-btn fab v-show="!item.deleteHide"
          color="rgba(0,0,0,0.56)" text small @click="deleteTweet(item.id)"><v-icon
              color="teal accent-3">mdi-check-circle-outline</v-icon>
          </v-btn>
          <v-btn fab v-show="!item.deleteHide" color="rgba(0,0,0,0.56)" text small
            @click="item.deleteHide = true"><v-icon
            color="red lighten-1">mdi-close-circle-outline</v-icon>
          </v-btn>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <div class="pagination-container" v-show="tableView & !loading">
      <div class="pagination-options">
        Elementos por página:
        <select aria-labelledby="select-page"
        v-model="pageSize" @blur="handlePageSizeChange($event)">
          <option v-for="size in pageSizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
        <div style="float: right">Total de tweets: {{ this.count }}</div>
      </div>
      <div class="pagination-wrapper">
        <b-pagination v-if="!loading" v-model="page"
        :total-rows="count" :per-page="pageSize" prev-text="Anterior"
          next-text="Siguiente" @change="handlePageChange"></b-pagination>
      </div>
    </div>
    <!-- End Table View -->
    <!-- Start Twitter View
    <div v-show="!tableView" v-if="!loading">
      <blockquote v-for="( tweet, index) in tweets" :key="index"
            class="twitter-tweet twitter-single--tweet-panel__body__embed-tweet"
          >
            <a :href="getEmbedTweet(tweet.originalId,tweet.user.username)">
              @{{ tweet.user.username }}/{{ tweet.originalId }}</a>
      </blockquote>
    </div> -->

  </div>
</template>
<script>

import authService from '@/services/auth.service';
import tweetService from '@/services/tweet.service';

export default {
  name: 'standard-table',
  props: {},
  data() {
    return {
      authService,
      isAdmin: false,
      labeled: {
        result: null,
        comment: null,
        id: null,
        proyectoID: null,
      },
      filter: {
        from: null,
        to: null,
        sentiments: null,
      },
      tweets: [],
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 5,
      pageSizes: [5, 10, 20],

      currentSort: 'date',
      currentSortOrder: 'desc',
      loading: true,
      cancelTokenSource: null,
      activeHoverItem: null,
      tableView: true,
    };
  },
  created() {
    // this.executeTwitterScript();
    if (
      this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol
      === 'ROLE_SUPER_ADMIN'
    ) {
      this.isAdmin = true;
    }
  },
  components: {
    // DataSelection,
  },
  watch: {
    currentSort() {
      this.getItems();
    },
    currentSortOrder() {
      this.getItems();
    },
  },
  methods: {
    onSelect(item) {
      console.log(item);
      // this.goToTweetView(item.originalId, item.user.username);
    },
    executeTwitterScript() {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
      document.body.appendChild(script);
    },

    getEmbedTweet(id, user) {
      return `https://twitter.com/${user}/status/${id}`;
    },

    checkIfIsRetweet(text) {
      // if text firts 3 chars are "RT " then is a retweet
      if (text.substring(0, 3) === 'RT ') {
        return true;
      }
      return false;
    },

    setTableView(bool) {
      this.tableView = bool;
    },

    getColorButtonView() {
      if (this.view === 'table') {
        return 'red';
      }
      return 'blue';
    },

    goToMaps(Latitud, Longitud) {
      if (Latitud && Longitud) {
        return `https://www.google.com/maps/@${Latitud}${','}${Longitud}${',14z'}`;
      }
      return 'https://www.google.com/maps/';
    },

    goToTweetView(id, user) {
      return this.$router.push(`/carga-de-twitter/${user}/${id}`);
    },

    updateFilter(from, to, sentiments) {
      this.filter.from = from;
      this.filter.to = to;
      this.filter.sentiments = sentiments;
      this.getItems();
    },

    getItems() {
      this.loading = true;
      tweetService.getTweetsFiltered(this.getRequestParams(this.page, this.pageSize))
        .then((response) => {
          this.tweets = response.content.map((tweet) => ({
            id: tweet.id,
            originalId: tweet.originalId,
            text: tweet.text,
            user: tweet.user,
            sentiment: tweet.sentiment,
            date: tweet.date,
            users: this.getAllTweetUsersFromText(tweet.text),
            deleteHide: true,
            likeCount: tweet.likeCount,
            retweetCount: tweet.retweetCount,
          }));

          this.count = response.totalElements;
          this.updateTotal();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // this.executeTwitterScript();
        });
    },

    deleteTweet(id) {
      tweetService.deleteTweet(id)
        .then(() => {
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTweetUserFromText(text) {
      const regex = /@(\w+)/g;
      const found = text.match(regex);
      if (found) {
        return found[0];
      }
      return '';
    },

    getAllTweetUsersFromText(text) {
      const regex = /@(\w+)/g;
      const found = text.match(regex);
      if (found) {
        // return removing brackets [ ] and format , to |
        // eslint-disable-next-line no-useless-escape
        return found.toString().replace(/[\[\]']+/g, '').replace(/,/g, ' | ');
      }
      return null;
    },

    handlePageChange(value) {
      this.page = value;
      this.getItems();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.getItems();
    },
    updateTotal() {
      this.$emit('updateTotal', this.count);
    },
    customSort() {
      return [];
    },
    getRequestParams(page, pageSize) {
      const params = {};
      params.sort = this.currentSort;
      params.direction = this.currentSortOrder;
      params.from = this.filter.from;
      params.to = this.filter.to;
      params.sentiments = this.filter.sentiments;

      if (page) {
        params.page = page - 1;
      }
      if (pageSize) {
        params.size = pageSize;
      }
      return params;
    },

    getSinceTime(date) {
      // in format Hace 7 días; Hace 1 día; Hace 1 hora; Hace 1 minuto
      const today = new Date();
      const myDate = new Date(date);
      const diff = today.getTime() - myDate.getTime();
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      const diffHours = Math.floor(diff / (1000 * 3600));
      const diffMinutes = Math.floor(diff / (1000 * 60));
      if (diffDays > 0) {
        return `Hace ${diffDays} días`;
      }
      if (diffHours > 0) {
        return `Hace ${diffHours} horas`;
      }
      if (diffMinutes > 0) {
        return `Hace ${diffMinutes} minutos`;
      }
      return 'Hace unos segundos';
    },
  },
};
</script>
<style lang="scss">
.centered-content-column div {
  display: flex;
  justify-content: center;
}

.max-content-column div {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  .md-table-cell-container {
    justify-content: center !important;
  }
}

.inherit-flex-flow {
  .md-table-cell-container {
    flex-flow: inherit;
  }
}

</style>
<style scoped>
.table_box {
  min-height: 150px;
  position: relative;
  width: 100%;
}
</style>
<style lang="scss" scoped>
.pagination-container {
  display: flex;
  flex-direction: column;
}

.pagination-options {
  margin-top: 1em;
  margin-bottom: 1em;
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    width: max-content;
  }
}

.md-drawer.md-persistent:not(.md-active).md-left+.md-app-container .md-content.dashboard_box {
  border-left: 1px solid #e5e5e5;
}

.loader-overlay {
  position: relative;
}
</style>
