<template>
  <div>
    <div class="breadcrumbs-container">
      <v-breadcrumbs :items="items">
        <template v-slot:itemt="{ itemt }">
          <v-breadcrumbs-item :to="itemt.to" :disabled="itemt.disabled">
            {{ itemt }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="twitter-single">
      <div class="twitter-single--tweet-panel mdtec-card twitter-single__left">
        <div class="mdtec-card--header tweet-panel--header">
          <h2 class="mdtec-card--header__title">Información del Tweet</h2>
          <div class="mdtec-card--header__actions" v-if="tweet && tweet.sentiment">
            <!-- vuetify chip with happy face icon -->
            <v-chip
              v-if="tweet.sentiment === '3' || tweet.sentiment === 'positive'"
              color="teal accent-2"
              class="tweet-panel--header--feeling"
              text-color="teal accent-5"
              dark
            >
              <v-icon left>mdi-emoticon-happy</v-icon>
              Positivo
            </v-chip>
            <v-chip
              v-if="tweet.sentiment === '2' || tweet.sentiment === 'neutral'"
              color="orange accent-1"
              class="tweet-panel--header--feeling"
              text-color="orange darken-3"
              dark
            >
              <v-icon left>mdi-emoticon-neutral</v-icon>
              Neutro
            </v-chip>
            <v-chip
              v-if="tweet.sentiment === '1' || tweet.sentiment === 'negative'"
              color="deep-purple accent-1"
              class="tweet-panel--header--feeling"
              text-color="deep-purple darken-4"
              dark
            >
              <v-icon left>mdi-emoticon-sad</v-icon>
              Negativo
            </v-chip>
          </div>
        </div>
        <div class="mdtec-card--body tweet-panel--body">
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__date" v-if="tweet && tweet.date">
              <label class="twiter-panel--field__label">Fecha:</label>
              <div class="twiter-panel--field__value" >
                {{ new Date(tweet.date).toLocaleDateString() }}
              </div>
            </div>
            <div class="twiter-panel--field twiter-panel--field__user">
              <label class="twiter-panel--field__label"
                >Usuario:</label
              >
              <div class="twiter-panel--field__value">@{{ user }}</div>
            </div>
          </div>
          <div class="field-row" v-if="tweet && tweet.sentiment">
            <div class="twiter-panel--field twiter-panel--field__feeling">
              <label class="twiter-panel--field__label">Sentimiento:</label>
              <div class="twiter-panel--field__value">
                <v-chip
                  v-if="tweet.sentiment === '3' || tweet.sentiment === 'positive'"
                  color="teal accent-2"
                  text-color="teal accent-5"
                  small
                  dark
                >
                  Positivo
                </v-chip>
                <v-chip
                  v-if="tweet.sentiment === '2' || tweet.sentiment === 'neutral'"
                  color="orange accent-1"
                  text-color="orange darken-3"
                  small
                  dark
                >
                  Neutro
                </v-chip>
                <v-chip
                  v-if="tweet.sentiment === '1' || tweet.sentiment === 'negative'"
                  color="deep-purple accent-1"
                  text-color="deep-purple darken-4"
                  small
                  dark
                >
                  Negativo
                </v-chip>

              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__tweet-content">
              <label class="twiter-panel--field__label">Contenido:</label>
              <div class="twiter-panel--field__value" v-if="tweet && tweet.text">
                {{ tweet.text }}}
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__link">
              <label class="twiter-panel--field__label">Link:</label>
              <div class="twiter-panel--field__value" v-if="this.embedTweet">
                <v-icon color="red accent-2">mdi-open-in-new</v-icon>
                <a
                  class="red--text text--accent-2"
                  :href="embedTweet"
                  v-text="this.trimString(this.embedTweet, 33)"
                ></a>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="twiter-panel--field twiter-panel--field__hashtags" v-if="hastagsInTweet">
              <label class="twiter-panel--field__label">Hashtags:</label>
              <div class="twiter-panel--field__value">
                <v-chip v-for="(hastag,index) in hastagsInTweet" :key="index"
                 color="grey lighten-1" small light> {{hastag}} </v-chip>
              </div>
            </div>
          </div>
          <blockquote
            class="twitter-tweet twitter-single--tweet-panel__body__embed-tweet"
          >
            <a :href="embedTweet">@{{ user }}/{{ id }}</a>
          </blockquote>
        </div>
      </div>

      <div class="twitter-single--user-panel mdtec-card twitter-single__right">
        <div class="mdtec-card--header tweet-panel--header">
          <h2 class="mdtec-card--header__title">Información del Usuario</h2>
          <div class="mdtec-card--header__actions"></div>
        </div>
        <div class="mdtec-card--body user-panel--body">
          <div class="user-panel--body__profile">
            <div class="user-panel--body__profile__avatar red accent-2" v-if="tweet && tweet.user">
              <img :src="tweet.user.profileImageUrl" :alt="user" />
            </div>
            <div class="user-panel--body__profile__info">
              <h3 class="user-panel--body__profile__info__name" v-if="tweet && tweet.user">
                {{ tweet.user.name }}
              </h3>
              <div class="user-panel--body__profile__info__username">
               @{{ user }}
              </div>
              <div class="user-panel--body__profile__info__description" v-if="tweet && tweet.user">
                {{ tweet.user.description }}
              </div>
              <div class="user-panel--body__profile__info__stats" v-if="tweet && tweet.user">
                <div class="user-panel--body__profile__info__stats__item">
                  <span
                    class="user-panel--body__profile__info__stats__item__label"
                  >
                    Seguidores:
                  </span>
                  <span
                    class="user-panel--body__profile__info__stats__item__value"
                  >
                  {{ tweet.user.followersCount }}
                  </span>
                </div>
                <div class="user-panel--body__profile__info__stats__item">
                  <span
                    class="user-panel--body__profile__info__stats__item__label"
                  >
                    Siguiendo:
                  </span>
                  <span
                    class="user-panel--body__profile__info__stats__item__value"
                  >
                  {{ tweet.user.followingCount }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <a
            class="twitter-timeline twitter-single--user-panel__embed-timeline"
            :href="embedUserTimeline"
          >
            @{{ user }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// eslint-disable-next-line import/no-cycle
import tweetService from '@/services/tweet.service';

export default {
  name: 'TwitterSingle',
  data() {
    return {
      id: null,
      user: null,
      tweet: null,
      items: [
        {
          text: 'Proyectos',
          disabled: false,
          to: '/seleccionar-proyecto',
        },
        {
          text: '',
          disabled: false,
          to: '/carga-de-twitter',
        },
        {
          text: 'Carga de Twitter',
          disabled: true,
          to: '/carga-de-twitter',
        },
        {
          text: `Vista de Tweet - ${this.$route.params.user}_${this.$route.params.id}`,
          disabled: true,
          to: '#',
        },
      ],
    };
  },
  mounted() {
    this.executeTwitterScript();
    this.items[1].text = this.$store.state.project;
    this.id = this.$route.params.id;
    this.user = this.$route.params.user;
    this.getTweet();
    // this.getTwitter();
    // execute this script <script async src="https://platform.twitter.com/widgets.js" charset="utf-8">
  },
  computed: {
    embedTweet() {
      if (this.id && this.user) {
        return `https://twitter.com/${this.user}/status/${this.id}`;
      }
      return null;
    },
    embedUserTimeline() {
      if (this.id && this.user) {
        return `https://twitter.com/${this.user}`;
      }
      return null;
    },
    hastagsInTweet() {
      if (this.tweet) {
        return this.getHastagsInTweet();
      }
      return null;
    },
  },
  methods: {
    getHastagsInTweet() {
      if (this.tweet) {
        const regex = /#(\w+)/g;
        const matches = this.tweet.text.match(regex);
        if (matches) {
          return matches;
        }
      }
      return null;
    },
    trimString(string, length) {
      if (string.length > length) {
        return `${string.substring(0, length)}...`;
      }
      return string;
    },
    executeTwitterScript() {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
      document.body.appendChild(script);
    },
    getTweet() {
      tweetService
        .getTweetByOriginalId(this.id)
        .then((response) => {
          this.tweet = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

</script>
<style lang="scss" scoped>
.twitter-single {
  display: grid;
  grid-gap: 0.825rem;
  grid-template-columns: repeat(14, 1fr);
  grid-auto-rows: min-content;

  .twitter-single--tweet-panel {
    grid-column: 1 / span 5;
    // height:100%;
    max-height: calc(var(--app-height) - 8rem);
    padding-bottom: 0;
    .tweet-panel--header {

      .tweet-panel--header--feeling {
        margin-top: -0.5rem;
        margin-right: -0.5rem;
      }
    }
    .tweet-panel--body {
      height: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      gap: 1rem;
      &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
      }

      .field-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap:1rem;
        .twiter-panel--field {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          font-size: 1rem;
          line-height: 1.5rem;
          gap: 0.5rem;
          width: 100%;
          &__label {
            font-weight: 500;
            min-width: 25%;
            color:#000;
            margin-bottom: 0;
          }
          &__value {
            font-weight: 400;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0.5rem;
            color:rgba(0,0,0,0.5);
          }
          &__date,
          &__user {
            gap: 0.25rem;
            width: auto;
            label {
              min-width: auto;
            }
          }
        }
      }

      .twitter-tweet {
        margin-top: 2rem;
      }
    }
  }
  .twitter-single--user-panel {
    grid-column: 6 / span 9;
    max-height: calc(var(--app-height) - 8rem);
    padding-bottom: 0;
    .user-panel--body{
      height: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      gap: 2rem;
      &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
      }
      .user-panel--body__profile{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap:1rem;
        padding: 1rem;
        // margin-top:0.5rem;
        &__avatar{
          width:5rem;
          height:5rem;
          border-radius: 50%;
          overflow:hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: center;
          }
        }
        &__info{
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          height:100%;
          gap:0.5rem;
          &__name{
            font-size: 1.375rem;
            line-height: 1rem;
            font-weight: 500;
            margin:0;
            text-transform: capitalize;
          }
          &__username{
            font-size: 1rem;
            line-height:1rem;
            font-weight: 400;
            color:rgba(0,0,0,0.5);
          }
          &__description{
            font-size: 1rem;
            line-height:1rem;
            font-weight: 400;
            font-style: italic;
          }
          &__stats{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap:0.5rem;
            &__item{
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              gap:0.25rem;
              &__label{
                font-size: 1rem;
                line-height:1rem;
                font-weight: 500;
              }
              &__value{
                font-size: 1rem;
                line-height:1rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.twitter-single .twitter-tweet.twitter-tweet-rendered {
  margin-bottom: 0 !important;
}
</style>
